<template>
  <div>
    <div :class="isInvalid(v$.login.companyCode.$error, 'mail')">
      <label class="input-label">{{ $t("company.login.company.label") }}</label>
      <drop-down v-model="login.companyCode" :placeholder="$t('common.select.placeholder')" :drawer-mode="true"
        :items="companies" :filterable="true" />
      <el-alert style="margin-top:10px" v-if="v$.login.companyCode.$error" :closable="false"
        :title="v$.login.companyCode.$errors[0].$message" type="error" show-icon />
    </div>
    <br />
    <div :class="isInvalid(v$.login.email.$error)">
      <label class="input-label">{{ $t("company.login.mail.input.label") }}</label>
      <el-input v-model="login.email" :placeholder="$t('company.login.mail.input.placeholder')"
        @focus="v$.login.email.$reset()" @keypress="handleSubmit">
        <template #prefix>
          <el-icon class="el-input__icon">
            <i class="bi bi-envelope-fill" />
          </el-icon>
        </template>
      </el-input>
      <el-alert style="margin-top:10px" v-if="v$.login.email.$error" :closable="false"
        :title="v$.login.email.$errors[0].$message" type="error" show-icon />
    </div>
    <div class="verify-code">
      <el-button @click="btnVerifyCodeOnClick">
        <span>{{ $t("company.login.mail.button.text") }}</span>
      </el-button>
    </div>
    <p class="faq" v-html="$t('company.login.faq')" />
  </div>
</template>

<style scoped>
.mail {
  margin-top: 40px;
}

.verify-code {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mail>>>.el-select {
  width: 100%;
}

.input-label {
  display: block;
  height: 30px;
  line-height: 30px;
}

.faq :deep(h5) {
  font-size: 14px;
}

.faq :deep(span) {
  line-height: 20px;
}
</style>

<script>
import useVuelidate from '@vuelidate/core'
import * as validators from '@/libs/i18n-validators'
import { isInvalid, handleCompanyException, getLink } from "@/libs/common"
import login from "@/http/apis/company/login"
import master from "@/http/apis/master";
import * as message from "@/plugins/message"
import DropDown from "@/components/DropDown.vue";
import constants from '../../libs/constants'

export default {
  name: "CompanyLoginEmail",
  components: { DropDown },
  setup() {
    return {
      v$: useVuelidate({
        $lazy: true,
        $autoDirty: false
      })
    }
  },
  validations() {
    return {
      login: {
        email: {
          required: validators.required,
          maxLength: validators.maxLength({ 
            limit: { num: 40 },
            unit: { num: constants.unit.character }
          }),
          email: validators.email
        },
        companyCode: {
          required: validators.required
        }
      }
    }
  },
  data() {
    return {
      login: {
        email: "",
        companyCode: null,
      },
      companies: {},
      loading: true
    };
  },
  emits: ["triggerLoading"],
  created() {
    this.loadData();
  },
  beforeRouteUpdate() {
    this.loadData();
  },
  methods: {
    handleSubmit(event) {
      if (event.keyCode == 13) {
        this.btnVerifyCodeOnClick();
      }
    },
    async btnVerifyCodeOnClick() {
      let validate = await this.v$.login.$validate();
      if (validate) {
        this.$emit("triggerLoading", true);
        login.getOTP(this.v$.login.$model).then(() => {
          this.$router.push({
            name: "CompanyLoginChallenge",
            params: {
              mail: this.login.email.toLowerCase(),
              companyCode: this.login.companyCode
            },
          });
        }).catch((err) => {
          err.loginInfo = this.login;
          handleCompanyException(err)
        }).finally(() => {
          this.$emit("triggerLoading", false);
        })
      }
    },
    isInvalid(invalid, extraClass = "") {
      return isInvalid(invalid, extraClass)
    },
    async loadData() {
      this.$emit("triggerLoading", true);
      let locale = this.$i18n.locale;
      try {
        let companies = await master.getCompanies(locale);
        if (companies) {
          this.companies = {
            data: companies.data,
            value: "value",
            label: "text",
          };
        }
        this.$emit("triggerLoading", false);
      } catch (err) {
        message.WarningAlertWithCallback(this.$i18n.t("exceptions.failGetData"), "", {
          closable: false,
          callback: () => { window.location.reload() }
        })
      } finally {
        this.loading = false;
      }
    },
    getLink(path) {
      return getLink(path)
    }
  },
};
</script>
